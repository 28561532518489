:root {
  --clr-primary: #8343f5;
  --clr-primary-dark: #6725d7;
  --clr-secondary: #626262;
  --clr-black: #000;
  --clr-white: #fff;
  --clr-card-background: #fafafa;
  --clr-pages-background: #edecec;
  --clr-ooredoo: #e30613;
  --clr-mobilis: #3da929;
  --clr-djezzy: #db0019;
  --clr-idoom: #2556a2;
  --clr-border: #cecece;
  --fs-12: 12px;
  --fs-14: 14px;
  --fs-16: 16px;
  --fs-18: 18px;
  --fs-20: 20px;
  --fs-22: 22px;
  --fs-26: 26px;
  --fs-30: 30px;
  --fs-34: 34px;
  --fs-48: 48px;
}

/*********************************************/
/*** utilities 
/*********************************************/
body {
  font-family: "Tajawal", sans-serif !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--clr-border);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--clr-border);
}

.wrapper {
  background: #f1f1f1;
  font-family: "Tajawal", sans-serif;
  min-height: 100vh;
  position: relative;
}
.pt-100 {
  padding-top: 100px;
}
.mt-100 {
  margin-top: 100px !important;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-50 {
  margin-bottom: 50px;
}
.card {
  background: var(--clr-card-background) !important;
  border: none !important;
}
.h-190 {
  height: 190px;
}
a {
  text-decoration: none !important;
}
.inactiveLink {
  pointer-events: none;
  cursor: default;
}
/*********************************************/
/*** custom styling 
/*********************************************/
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--clr-primary) !important;
}

.MuiButton-root,
.css-1aquho2-MuiTabs-indicator {
  background-color: var(--clr-primary) !important;
}
.MuiContainer-root {
  margin-top: 150px;
}

.navbar-brand-holder {
  padding-inline-start: 10px;
}

.navbar-brand {
  color: var(--clr-primary) !important;
  font-weight: 500;
}
.navbar-brand-holder svg {
  color: var(--clr-white) !important;
  cursor: pointer;
}
nav.navbar {
  box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.4);
  padding-inline-start: 120px;
  position: fixed;
  width: 100%;
  top: 0;
  transition: 0.4s ease-in-out;
  background-color: var(--clr-primary);
  z-index: 10;
  background: -webkit-linear-gradient(to right, #8343f5,
    #c944ff);
  background: linear-gradient(to right, #8343f5, #c944ff);
  
}
nav.navbar .navbar-brand {
  color: var(--clr-white) !important;
}
nav.navbar.collapsed {
  padding-inline-start: 250px;
}
.navbar_right a {
  background-color: #fff !important;
  padding: 8px 20px;
  margin: 0 5px;
  font-size: 16px;
  border-radius: 5px;
  color: var(--clr-primary);
}
.sidebar {
  width: 120px;
  height: 200%;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  background: var(--clr-white);
  box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.4);
  border-right: 2px solid rgba(255, 255, 255, 0.09);
  transition: 0.2s ease-in-out;
  position: fixed;
  top: 0;
  z-index: 10;
  padding: 0 15px;
  align-items: center;
  overflow: auto;
  padding-bottom: 150px !important;
}

.sidebar.open {
  width: 270px;
  padding: 0 17px;
  padding-bottom: 150px !important;
}

.sidebar .logo {
  width: 100%;
  height: 240px;
  padding: 40px 0;
  display: grid;
  align-items: center;
 
}

.sidebar .logo img {
  width: 56px;
  transition: 0.4s;
  border-radius: 4px;
  margin: auto;
}

.sidebar.open .logo img {
  width: 96px;
}

.sidebar .logo .username {
  color: var(--clr-black);
  margin-top: 12px;
  font-variant: small-caps;
  pointer-events: none;
  scale: 0;
  opacity: 0;
  text-align: center;
  font-weight: 700;
}

.sidebar.open .logo .username {
  scale: 1;
  opacity: 1;
  transition: 0.4s;
  transition-delay: 0.2s;
}
.sidebar .logo .userPhone {
  color: var(--clr-secondary);
  text-align: center;
  scale: 0;
  opacity: 0;
}
.sidebar.open .logo .userPhone {
  scale: 1;
  opacity: 1;
  transition: 0.4s;
  transition-delay: 0.2s;
}
.sidebar .logo .user-infos {
  color: var(--clr-secondary);
  text-align: center;
  scale: 0;
  opacity: 0;
}
.sidebar.open .logo .user-infos {
  scale: 1;
  opacity: 1;
  transition: 0.4s;
  transition-delay: 0.2s;
}
.sidebar ul {
  margin-top: 45px;
  padding-left: 0 !important;
  overflow: auto;
  padding-bottom: 150px !important;
  margin-bottom: 400px;

}
.sidebar .nav-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  width: 53%;
  height: 50px;
  padding: 0 10px;
  margin: 8px 0;
  color: var(--clr-black);
  transition: 0.3s;
}
.sidebar.open .nav-item {
  width: 100%;
}
.sidebar .nav-item.active {
  color: var(--clr-white);
  background: var(--clr-primary) !important;
  background: -webkit-linear-gradient(to bottom, #8343f5, #c944ff) !important;
  background: linear-gradient(to bottom, #8343f5, #c944ff) !important;
}

.sidebar .nav-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.sidebar .nav-item span {
  font-size: 18px;
  margin-left: 8px;
  opacity: 0;
  pointer-events: none;
}

.sidebar.open .nav-item span {
  opacity: 1;
  pointer-events: visible;
  transition: 0.4s;
  transition-delay: 0.2s;
}

.sidebar hr {
  width: 100%;
  height: 2px;
  border-radius: 3px;
  margin: 40px 0 50px;
  background: rgba(255, 255, 255, 0.1);
  opacity: 0;
}

.sidebar.open hr {
  opacity: 1;
  transition: 0.4s;
}
.user-infos-span {
  font-size: 16px;
  font-weight: bold;
  color: #494949;
  font-family: "Tajawal", sans-serif !important;
}

.main-content {
  margin-inline-start: 120px;
  padding-top: 75px;
}
.pagetitle {
  margin: 30px 0 30px 0;
  font-weight: bold;
  font-size: 28px;
  font-family: "Tajawal", sans-serif;
  color: #3e3e3e;
 
}
.pagetitle_intern {
  margin: 10px 0 20px 0;
  font-weight: bold;
  font-size: 28px;
  font-family: "Tajawal", sans-serif;
  color: var(--clr-primary);
}
.statistics{
  border-bottom: 5px solid #b444fc !important;
}
.red_stat{
  border-bottom: 5px solid #f95c6b !important;
}

.statistics .stat-item-title {
  color: var(--clr-primary);
  font-weight: 600;
  background: -webkit-linear-gradient(#8343f5,
    #c944ff);
  background-clip: text;
  -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.statistics .stat-item-subtitle {
  color: var(--clr-secondary);
  
}
.statistics-wrapper {
  padding-top: 75px;
}
.element_shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 28px 0px;
}
.statistics .red_txt{
  color: #f95c6b ;
  background: -webkit-linear-gradient(#f52035,
    #f36c79);
  background-clip: text;
  -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
/*
.element_shadow:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}*/
.ops_img_mobilis {
  width: 130px;
  height: 80px;
  margin-top: 40px;
}
.ops_img {
  width: 120px;
  height: 120px;
  margin-top: 20px;
}
.ops_img_fatcure {
  width: 130px;
  height: 130px;
  margin-top: 18px;
}

.ops_img_inter {
  width: 200px;
  height: 120px;
  margin-top: 18px;
}
.card_img_inter{
  width: 100%;
  height: 159px;
  border-radius: 4px;
}
.flexy-page .flexy-items-holder {
  background: #c279ff2e;
    margin-bottom: 41px;
    border-radius: 4px;
}
.flexy-page .flexy-item {
  cursor: pointer;
  padding: 5px 10px;
}
.flexy-page .flexy-subtitle {
      font-weight: 600;
        color: var(--clr-black);
        font-size: var(--fs-22);
        margin-top: -17px;
        width: auto;
        background: var(--clr-white);
        padding: 6px 30px 1px 30px;
}

.flexy-page .flexy-amount {
  font-weight: 600;
  color: var(--clr-primary);
  font-size: var(--fs-16);
}
.white_text {
  color: var(--clr-white) !important;
}
.flexy-page .points {
  position: absolute;
  bottom: 0px;
  right: 10px;
  font-size: var(--fs-16);
  display: flex;
  color: var(--clr-ooredoo);
  font-weight: bold;
  align-items: flex-end;
}
.send_btn {
  background-color: #8343f5 !important;

  margin: 20px auto !important;
  color: #fff !important;
  padding: 10px 24px 9px 14px !important;
  width: 60% !important;
  border-radius: 43px !important;
  height: 52px;
}
.send_btn_disabled {
  background-color: #b38ef7 !important;
}
.input_flexy{
    margin: 0px 0px 15px 0px !important;
}
.input_flexy input {
  font-size: 26px !important;
  font-weight: bold !important;
  color: #333 !important;
}
.input_flexy_start p {
  font-size: 26px !important;
  font-weight: bold !important;
  color: #333 !important;
}

.input_flexy_end p{
font-size: 18px !important;

  color: #333 !important;
}
.transactions-page #vertical-tabpanel-0,
.transactions-page #vertical-tabpanel-1 {
  flex: 1;
}
.transactions-page .transaction-item .phone,
.transactions-page .transaction-item .amount {
  font-size: var(--fs-18);
  font-weight: 600;
}
.transactions-page .transaction-item .phone {
  color: var(--clr-secondary);
  margin-bottom: 10px;
}
.transactions-page .transaction-item .amount {
  color: var(--clr-primary);
}
.transactions-page .transaction-item .dealer {
  text-align: center;
  color: var(--clr-white);
  border-radius: 20px;
  padding: 4px 15px !important;
  margin-right: 10px !important;
  font-size: 14px;
}

.transactions-page .transaction-item .dealer_recharge {
  text-align: center;
  color: var(--clr-white);
  border-radius: 20px;
  margin-right: 10px !important;
  font-size: 12px;
  padding: 2px 15px !important;
}
.transactions-page .transaction-item .dealer.ooredoo {
  background: var(--clr-primary);
}
.transactions-page .transaction-item .dealer.mobilis {
  background: var(--clr-primary);
}
.transactions-page .transaction-item .dealer.djezzy {
  background: var(--clr-primary);
}
.transactions-page .transaction-item .transaction-date {
  color: var(--clr-secondary);
  font-size: var(--fs-14);
}
.transactions-page .transaction-item .transaction-date span {
  margin-left: 20px !important;
}
.transactions-page .transaction-item .recharge {
  background-color: var(--clr-primary);
  color: var(--clr-white);
  border-radius: 20px;
  padding: 0px 10px;
}
.idoom-page {
  padding-top: 200px;
}
.idoom-page .unavailable-status {
  position: absolute;
  color: var(--clr-white);
  background-color: var(--clr-ooredoo);
  top: 15px;
  left: 15px;
  border-radius: 20px;
  padding: 3px 14px 0px 14px;
}
.idoom-page .overlay {
  position: absolute;
  content: "";
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  top: 0;
  width: 100%;
}
.selectedbutton {
  background: var(--clr-primary) !important;
}
.selectedoffer {
  background: #ff5722;
    padding: 14px;
    color: #fff;
    border-radius: 4px;
    width: 100%;
 
}

.annuler_btn {
  background-color: #db0019 !important;
  color: #fff !important;
  font-size: 12px !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  width: 138px !important;
  min-width: 100px !important;
}
.close_btn{
    background-color: #db0019 !important;
    color: #fff !important;
    font-size: 12px !important;
    box-shadow: none !important;
    border-radius: 50px !important;
    
    min-width: 38px !important;
  }
.confirm_btn {
  background-color: var(--clr-primary) !important;
  color: #fff !important;
  font-size: 12px !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  width: 138px !important;
  min-width: 138px !important;
}

.confirm_btn_green {
  background-color: var(--clr-mobilis) !important;
  color: #fff !important;
  font-size: 12px !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  min-width: 138px !important;
}

.continue_btn {
  background-color: #3da929 !important;
  color: #fff !important;
      font-size: 12px !important;
        box-shadow: none !important;
        border-radius: 4px !important;
        min-width: 100px !important;
}

.responseModalList {
  font-weight: bold;
}
.status_img {
  width: 150px;
}
.btn_page {
  width: 243px;
  margin: 0 auto;
  align-self: center;
  height: 45px;
  border-radius: 50px !important;

}
.btn_page_musted{
  background-color: #c9c7c7 !important;
}
.msg_status {
  text-align: center;
  margin: 10px auto 10px auto;
  width: 500px;
}
.msg_status_container {
  margin: 0 auto !important;
  max-width: 900px !important;
}
.loading_img {
  text-align: center;
  margin-top: 100px;
}
.card_img {
  width: 400px;
  border-radius: 5px;
}
.internet_card {
  width: 420px !important;
  text-align: center;
  background: #edecec !important;
}
.internet_card .card-body {
  background: #edecec !important;
  padding: 0;
}

.loadmore_btn {
  margin-top: 20px !important;
  width: 200px !important;
  border-radius: 20px !important;
}
.stats-wrapper {
  padding-top: 15px;
}
.recharge_item_amount {
  font-weight: bold;
  color: #8343f5;
  font-size: 24px !important;
}
.recharge_item_subtitle {
  font-size: 18px !important;
}
.unpaid {
  background-color: #f4433691;
  border-radius: 5px;
}
.paid {
  background-color: #3da92957;
  border-radius: 5px;
}
.phone_suggestions {
  height: 200px;
  overflow-y: scroll;
  background-color: #fff;
  box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 72px;
  z-index: 999;
  left: 20px;
  right: 4px;
  border-bottom: 1px solid #c9c7c7;
  border-left: 1px solid #c9c7c7;
  border-right: 1px solid #c9c7c7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.phone_suggestions_text span {
  font-size: 20px !important;
  font-weight: bold;
}
.phone_suggestions_text p{

    float: right;
      margin-top: -30px;
      font-weight: bold;
}
.rechargement_card {
  width: 84%;
  margin: 0 AUTO;
  border-radius: 5px;
  background-color: transparent !important;
}
.keydown {
  color: var(--clr-secondary);
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 15px;
}
.keydown img {
  width: 30px;
  margin-left: 5px;
}
.floating_card_img {
  position: absolute;
  width: 90px;
  right: 20px;
}

.pass-page, .info-page{
      padding-top: 9px;
}
.settings{
 
    border-radius: 5px;
    margin: 5px;
}
.settings-menu {
  margin: 53px auto 53px 138px !important;
  max-width: 80%;
  padding-top: 18px !important;
}
.sold_status{
  max-width: 75%;
  margin: 0 auto;
}
.operatorstatus{
  color: red;
    font-size: 19px;
    font-weight: bold;
    position: absolute;
    left: 11px;
    bottom: 0px;
}
.productcardbody{
  padding-bottom: 0px !important;
}
.productcard{
  box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px !important;
    border-radius: 16px !important;
    padding: 10px !important;
    margin: 0 auto;
    position: relative;
}
.superdeal{
  width: 50px;
  left: 7px;
  position: absolute;
}
.producttitle{
  font-size: 18px;
  font-weight: Bold;
  color: #333;
  margin-bottom: 0;
}
.productprice{
font-size: 20px;
font-weight: Bold;
color: #8343f5;
    margin-bottom: 0;
}
.productqty{
  font-size: 15px;
  font-weight: normal;
  color: #626262;
  }
  .addtocart{
    float: right;
    background-color: #ff5f00 !important;
    color: #fff !important;
    margin-top: 21px !important;
  }
  .pricing_card .points {
    position: absolute;
    
    bottom: 4px;
    right: 4px;
    font-size: var(--fs-18);
    display: inline-block;
    color: #fff;
    padding: 4px 10px 0px 10px;
    border-radius: 3px;
}
.pts_icon{
  margin-bottom: 5px;
  margin-right: 4px;
}
.event {
  top: 55px;
  position: absolute;
  left: 80px;
  right: 0;
}
.event img{
  height: 200px;
  transform: translatex(0px);
  animation: float 6s ease-in-out infinite;
}
@keyframes float {
  0% {
      transform: translatex(0px);
  }
  50% {
      transform: translatex(-20px);
  }
  100% {
      transform: translatex(0px);
  }
}
.costumer_card{
  box-shadow: none !important;
  background: #fff;
  margin: 10px;
  text-align: center 
}
.menu_items{
    margin-bottom: 10px !important;
    padding: 10px !important;
    border-radius: 5px !important;
}
.menu_items:focus {
  background: -webkit-linear-gradient(to right, #8343f5,
        #c944ff) !important;
    background: linear-gradient(to right, #8343f5, #c944ff) !important;
    color: #FFF !important;
}

.main-content iframe{
margin-top: -75px;
}
.contactbtn{
        color: #fff !important;
          position: fixed !important;
          right: -25px !important;
          top: 40% !important;
          padding: 10px 22px !important;
          border-radius: 0 !important;
          rotate: 90deg;
          z-index: 99;
}
.contact_badge {
  color: #fff;
  padding: 3px 10px 3px 10px;
  border-radius: 18px;
  font-size: 13px;}
.ooredoo{
  background: #ff4550;
}
.djezzy {
  background: #e45565;
}
.mobilis {
  background: #75be67;
}
.btn_page_addcontact{
  background-color: #FF5722 !important;
    width: 243px;
    margin: 0 auto 12px auto !important;
    align-self: center;
    height: 45px;
    border-radius: 50px !important;
}
.btn_page_adddebt{
  background-color: #93b110 !important;
    width: 243px;
    margin: 0 auto 12px auto !important;
    align-self: center;
    height: 45px;
    border-radius: 50px !important;
}
.paycard{
    width: 500px !important;
      height: auto;
}
.cardamount{
    position: absolute;
      bottom: 39px;
      left: -83px;
      font-size: 34px;
      color: #fff;
      font-family: "Tajawal", sans-serif !important;
}
.btn-category{
        background-color: #fff !important;
        padding: 10px 20px !important;
        border-radius: 9px !important;
        margin: 5px 10px ;
        color: #000 !important;
        font-size: 20px;
        font-family: "Tajawal", sans-serif !important;
        width: 131px;
}
.btn-cat-selected {
  background-color: var(--clr-primary)!important;
color: #fff !important;
border-radius: 25px !important;
}
.menu_pro{
  background: #fff;
  max-width: 80% !important;
  border-radius: 28px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-bottom: 52px;
}
.recharge_pro{
      background: #fff !important;
        border-radius: 10px;
        padding-bottom: 0;
}
.recharge_item_name{
      margin-bottom: 5px;
       text-transform: capitalize;
        font-size: 18px;
        font-family: "Tajawal", sans-serif !important;
        font-weight: bold;
}
.recharge_item_pro_title {
  margin-bottom: 5px;
  color: #393939 !important;
  font-size: 16px;
  font-family: "Tajawal", sans-serif !important;
  font-weight: bold;
}
.recharge_item_pro_amount{
      font-size: 21px;
        font-weight: bold;
        margin-bottom: 5px;
}
.primary{
color: #8343f5;
}
.red{
color: red;
}
.green{
color: #3da929;
}
.balance-list{
      display: block;
      
        padding: 11px;
        background: #f1f1f1;
        border-radius: 4px;
        margin-bottom: 5px;
}
.nested_header {
  text-align: center;
  padding-left: 0px !important;
  text-transform: capitalize;
}

#region-simple-select{
      text-align: left;
        padding: 12px;
}
#searchproaccount{
      padding: 12px;
      border-radius: 30px;
}
.tuto{
  margin: 53px auto 53px 138px !important;
    max-width: 80%;
    padding-top: 85px !important;
   
}
.tuto_title{
  text-align: left;
    margin: 10px 0;
}
.tuto_card{
  height: 270px;
  margin-bottom: 60px;
  border-radius: 5px;

}
.unpaid_row{
      background: #ffd7d7 !important;
}

.paid_row {
      background: #b9f4d2 !important;
}
.lateclient{
background: #ffd7d7 !important;
}
.offerdescp{
    margin: 20px auto !important;
    border-radius: 5px;
}
.offerdescp span{
      font-size: 16px;
        text-transform: capitalize;
      font-family: "Tajawal", sans-serif !important;
}
.check-transaction{
      max-width: 79%;
        margin: auto;
        margin-bottom: 19px;
        font-size: 15px
}
.card_img_zoom{
  margin: 40px auto;
}
.card_img_btn{
  background-color: #f1f1f1 !important;
}
.switch_img{
  width: 100px;
  height: 100px;;
}
.switchaccount_btn{
      text-align: center;
        border-radius: 5px;
        color: #626262;
}
.switchaccount_btn:hover{
    cursor: pointer;
    background-color:var(--clr-primary) !important;
    color: #fff;
  }
.switch_modal{
padding: 40px 20px 40px 20px;
    }
.switch_modal h2{
      color: #626262;
      font-family: "Tajawal", sans-serif;
      font-size: 22px;
}
.navigation_pro{
  display: none !important;
}
.btn_block{
      text-align: center;
        justify-content: space-evenly;
        display: flex;
        max-width: 338px;
        margin: 21px auto 0 auto;
}
.centred_alert{
  display: block !important;
    text-align: center !important;
    font-size: 16px !important;
}
.centred_alert .MuiAlert-icon{
      margin: 0 auto;
        max-width: 50px;
        font-size: 39px;
}
.amount_btn{
    width: 100%;
      color: #8343f5 !important;
      background: #ebeaea !important;
      border: none !important;
      font-size: 15px !important;
      text-transform: capitalize !important;
}
.amount_btn_selected{
  color: #fff !important;
    background: #8343f5 !important;
}
.pay_row{
  background: #fff !important;
}
.pay_icon{
width: 80px;
}
.payment_item_subtitle{
font-size: 25px !important;
  margin-bottom: 5px !important;
  font-weight: bold !important;
  color: #94D82D;
}
.payment_item_amount{
  font-size: 15px !important;
    margin-bottom: 5px !important;

}
.each-slide-effect{
  padding: 10px 0;
  margin: 10px;
  background-color: #fff;
  border-radius: 5px;
}
.each-slide-effect .slide-element-img {
       
        background-size: contain;
        height: 230px;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-position: center;
}

.each-slide-effect .slide-element {
      padding: 10px 20px 0px 20px;
        font-size: 20px;
        text-align: center;
        width: 100%;
        color: #333;
    
}
.each-slide-effect .slide-element p{
  margin-bottom: 0px;
  font-weight: bold;
}
.slide-price{
  color: var(--clr-primary) !important;
  font-weight: bold;
  font-family: "Tajawal", sans-serif !important;
  font-size: 18px;
}
.slide-price-offer {
  color:  #333 !important;
    font-weight: normal;
    font-family: "Tajawal", sans-serif !important;
    font-size: 18px;
  text-decoration: line-through;
  margin-left: 10px;
}
.games_price{
  font-size: 26px;
    color: #ae45fc;
    text-align: center;
    font-weight: bold;
    background: #F1F1F1;
    padding: 10px;
    font-family: "Tajawal",
      sans-serif !important;
}
.games_page_pubg{
  background-image: url("/public/images/pubgbg.jpg");
  background-color: #cccccc;
        min-height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        animation-name: slide;
          animation-duration: 14s;
          animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;

  padding-top: 200px;
}
.games_page_fire{
  background-image: url("/public/images/freefirebg.jpeg");
  background-color: #cccccc;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation-name: slide2;
  animation-duration: 14s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  padding-top: 200px;
}
@keyframes slide {
  0% {
      background-position: top;
    }
  
    50% {
      background-position: bottom;
    }
  
    100% {
      background-position: top;
    }
}
@keyframes slide2 {
  0% {
    background-position: top;
  }

  50% {
    background-position: bottom;
  }

  100% {
    background-position: top;
  }
}

.game_code{
      background: #fff;
        max-width: 50%;
        margin: 0 auto;
        padding: 10px;
        border-radius: 5px;
}
.tableTitle{
  margin-bottom: 0px;
  border-radius: 4px;
  background-color: #fff;
  border: none;
  margin-top: 10px;
 
}
.todaytotalp{
      text-align: left;
        margin-top: 10px;
        max-width: 275px;
        background: #8343f5;
        padding: 10px 20px;
        color: #fff;
        font-weight: bold !important;
        border-radius: 30px;
}
.form_redmed{
  background: #fff;
  
    border-radius: 30px;
}
.form_redmed fieldset{
  border: none;
}
.searchbyname .MuiFormControl-root{
  border-radius: 30px;
}
.searchbyname fieldset{
  border: none;
}
.pro_tables{
  box-shadow: none !important;
  border-radius: 5px;
}
.table_p_title{
  font-size: 15px !important;
  font-weight: bold !important;
}
.input_add_customer fieldset{
  border: none;
}
.input_add_customer{
  border-radius: 30px;
 
}
.input_add_customer input{
  font-size: 14px;
}
.resp_stats .card{
  text-align: left;
}
.input_add_balance fieldset{
  border: none;
}
.input_add_balance{
  border-radius: 30px;
  background-color: #f2f2f2 !important;
}
.input_add_balance input{
  font-size: 14px;
}
.operator_icon{
  width: 25px;
  margin: 0 5px;
}
.mobilis_icon{
  width: 45px;
  margin: 0 5px;
}

.delete_btn{
  font-size: 13px !important;
  color:#f1f1f1 !important;
  background-color: #f52035 !important;
  padding: 4px 15px !important;
  float: right;
}
.name{
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
  
}
.total_dette{
  background-color: var(--clr-primary);
  padding: 5px 10px;
  color: #fff;
  border-radius: 4px;
  
}
.add_to_dette{
  font-size: 13px !important;
  color: #f1f1f1 !important;
  background-color: #343A40 !important;
  padding: 6px 15px 6px 20px !important;
  float: right;
  margin-bottom: -15px;
  margin-right: -17px;
  border-radius: 7px 0px 5px 50px !important;
}
.input_msg{
  font-family: 'Tajawal';
  font-size: 14px;
  font-weight: bold;
  color: red;
}
.login_title{
  font-family: 'Tajawal';
    font-size: 23px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #333;
}

.login_container{
   background-color: #f1f1f1;
    padding: 30px;
    border-radius: 35px;
    margin-top: 50px !important;
   
    
   
}
.login_input input{
  background: #fff;
  font-family: "Tajawal", sans-serif !important;
  font-size: 15px;
}
.login_input fieldset{
  border: none;
}
.login_input label,.login_input_label{

  font-family: "Tajawal", sans-serif !important;
  font-size: 15px;
}

.transactionbtn{
  color: #fff !important;
    position: fixed !important;
    right: -42px !important;
    top: 59% !important;
    padding: 10px 22px !important;
    border-radius: 0 !important;
    rotate: 90deg;
    z-index: 99;
}
.latest_transprimary_text{
  font-size: 20px ;
  color: #333 ;
  font-weight: bold;
}
.trans_item{
  background-color: #f1f1f1;
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 5px;
}

.operator_btn_home{
  height: 190px;
  margin-bottom: 20px !important;
}

.operator_btn_home .card{
  height: 190px;
}

.operator_btn_home .card-body{
  height: 190px;
}
.overlay_modal .css-rnmm7m-MuiPaper-root-MuiDialog-paper{
  margin: 32px 0px !important;
  width: calc(100% - 19px);
}
.sim_hist{
  background: #EBD3F8 !important;
}
.sim_resposne{
  padding: 20px 20px 2px 20px;
  background: #f1f1f1;
  border-radius: 7px;
  margin-top: 20px !important;
  width: 80% !important;
  margin-inline: auto !important;
  
}
.shop_cart{
  position: fixed !important;
  right: 10px;
  bottom: 100px;
  z-index: 999;
  padding: 10px 20px !important;
    border-radius: 50px !important;
}
.store_product_serach{
  justify-content: center;
  margin-bottom: 30px ;
}
.store_product_serach fieldset{
 
  border: none;
 
}
.store_product_serach input{
  background: #fff;
    border: none;
    padding: 10px 20px;
    width: 50%;
    height: 35px;
    border-radius: 30px !important;
    margin: 20px auto;
    
}
.store_product_serach p{
  width: 50%;
  margin:-15px 0 0 0;
  padding: 0;
}

.store_categories button {
  border-style: outset !important;
  border: none !important;
  background: #fff;
  border-radius: 4px !important;
  padding: 9px !important;
  margin: 0 10px;
 
}

.store_categories .actif_btn {
 color: #fff !important;
  background: #b744fd !important;
  
}
.deals{
  height: 250px;
  width: 100%;
  border-radius: 5px;
  background-color: #FF184F;
}
.deals img{
  height: 235px;
  position: relative;
  animation: float 3s ease-in-out infinite;
}
@keyframes float {
  0% {
      transform: translateY(0);
  }

  50% {
      transform: translateY(-20px);
  }

  100% {
      transform: translateY(0);
  }
}
.deals h5{
  font-size: 43px;
    color: #fff;
    margin-top: 10px;
}
.deals p{
  font-size: 25px;
    color: #fff;
}
.deals a{
  font-size: 16px;
    color: #333;
    background: #fff;
    border-radius: 30px;
    padding: 5px 20px;
    font-weight: bold;
}
.deals a:hover{
  
    color: #FF184F;
   
}
.special_offer{
  display: flex
  ;
      text-align: center;
      justify-items: center;
      justify-content: center;
      margin-bottom: 30px;
}
.special_offer img{
  width: 40px;
  margin: 28px 0px 0px -23px;
}

/******************************************************************** media query ***************************************/
@media only screen and (max-width: 991px) {
  nav .nav-link.active {
    background: none;
    color: var(--clr-primary) !important;
  }
  nav .navbar-collapse {
    margin-top: 30px;
  }
  .flexy-page .flexy-items-holder {
    margin-top: 40px;
  }
    .navigation_pro {
      display: block !important;
    }
    .menu_pro{
      display: none;
    }
        .game_code {
          max-width: 100%;
        }
        
.OfferModalPending h6{
      background: var(--clr-primary);
        padding: 10px;
        color: #FFF;
}
.OfferModalPending{
      text-align: center;
}



.store_categories::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.store_categories{
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  scrollbar-width: none; /* Firefox */
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}
.store_categories button {
 
  flex: 0 0 auto !important; 
}
}
@media only screen and (max-width: 600px) {
  .sidebar .logo{
    font-size: 16px;
  }
    .sidebar .logo .username{font-variant: initial;font-size: 18px;}
  .sold_status{
    max-width: 90%;
 
  }
  .sidebar {
    left: -120px;
  }
  .sidebar.open {
    left: 0;
  }
  nav.navbar {
    padding-inline-start: 0;
  }
  .navbar_right {
    display: none;
  }
  .main-content {
    margin-inline-start: 0px;
  }
  .recharge_item_amount {
    font-size: 17px !important;
  }
  .recharge_item_subtitle {
    font-size: 15px !important;
    margin-bottom: 5px !important;
  }
  .rechargement_card {
    width: 95%;
  }
  .sidebar .nav-item span {
    font-size: 16px;
  }
  .transactions-page .transaction-item .transaction-date {
    color: var(--clr-secondary);
    font-size: var(--fs-12);
  }
  .transactions-page .transaction-item .transaction-date span {
    margin-left: 0px !important;
    float: left;
  }
  .keydown {
    display: none;
  }
  .send_btn {

    width: 50% !important;
    height: 46px;
  }

.event img {
  height: 80px;
 
}
.event {

  left: 10px;
  right: 20;
}

.settings-menu {
  margin: 54px auto !important;
  max-width: 94%;
}

.pagetitle_intern{
  font-size: 21px;
}
.categorybtnList{
  display: initial !important;
 
}
.btn-category{
margin: 5px 11px;

}
.add-client-form{
      margin-bottom: 40px;
}
.recharge_item_pro_title{
    border-bottom: 1px solid #ddd;
      padding-bottom: 5px;
}
#select-account{
  font-size: 13px;
}
.balance-list{
      display: block !important;
}
.tuto {
  margin:50px auto !important;
  padding-top: 30px !important;
 
}
.tuto_card {
  height: 202px;
  margin-bottom: 70px;
}
.transactionbtn{
  font-size: 11px !important;
  padding: 9px 16px !important;
  right: -39px !important;
  top: 54% !important;
}
.contactbtn{
  padding: 9px 16px !important;
  font-size: 11px !important;
}
.card_img {
  width: 300px;

}

.internet_card {
  width: 320px !important;

}

.navigation_pro .css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root {
  min-width: 35px !important;
}
.idoom-page{
   padding-top: 74px;
}
.resp_stats{
  margin-top: 20px;
}
.todaytotalp{
  margin-top: 20px !important;
  font-size: 14px !important;
}

.rechargelist{
  margin: 0 auto !important;
}
.statistics-wrap{
  padding: 4px !important;
  font-size: 13px;
}
.pagetitle {
  margin: 20px 0 20px 0;
  font-size: 23px;
}
.each-slide-effect .slide-element-img{height: 150px}

.each-slide-effect .slide-element p{font-size: 15px;}
.slide-price{font-size:15px}

.operator_btn_home {
 height: 120px;
 margin-bottom: 10px !important;
 padding: 5px !important;
}
.operator_btn_home .card{
  height: 120px;
}
.operator_btn_home .card-body{
  height: 120px;
}
.ops_img_mobilis {
  width: 100px;
  height: 50px;
  margin-top: 20px;
}
.ops_img {
  width: 65px;
  height: 65px;
  margin-top: 15px;
}
.ops_img_fatcure {
  width: 80px;
  height: 80px;
  margin-top: 8px;
}
.ops_img_inter {
  width: 120px;
  height: 80px;
  margin-top: 5px;
}
.card_img_inter{
  width: 100%;
  height: 80px;
  border-radius: 4px;
}

.pro_action_btn{
  padding: 5px !important;
  min-width: 35px !important; 
}
#region-simple-select {
  font-size: 12px;
}

#searchproaccount {
  font-size: 12px;
}

.store_product_serach input{
 
    width: 80%;
    height: 25px;

    
}
.store_product_serach p{
  width: 80%;
  font-size: 12px;
}

.deals img{
  height: 158px;
}
.deals h5 {
  font-size: 28px;
}
.deals p {
  font-size: 17px;
}
.deals a {
  font-size: 12px;
}
.deals {
  height: 198px;
  border-radius: 0;
}

}

@media only screen and (max-width: 400px) {

.navigation_pro .Mui-selected{
  font-size: 9px !important;
}
    
.navigation_pro  svg{
  width: 25px;
}


}